import React, { useContext, useRef } from "react";
import { PrintContext } from "../../context/PrintContext";
import { useReactToPrint } from "react-to-print";

function PdfLayout({ setPrintView }) {
  const { printData } = useContext(PrintContext);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp-data",
    // onAfterPrint: () => alert("Print successful"),
  });

  let dataDisplay = printData[0];

  console.log(dataDisplay);

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 h-screen w-full bg-[#00000099] flex justify-center items-center py-5">
      <div className="bg-gray-50 w-4/5 h-full rounded-md overflow-hidden">
        <div className=" flex justify-end items-center gap-3 p-2">
          <button onClick={handlePrint} className="bg-gray-300">
            Print
          </button>
          <button onClick={() => setPrintView(false)} className="bg-gray-300">
            Cancel
          </button>
        </div>
        {/* data to print below */}
        <div ref={componentRef} style={{ width: "100%", height: window.innerHeight }}>
          <div>
            <h5>Personal Details</h5>
            <h3> Name(S) {dataDisplay.details.firstname}</h3>
            <h3> Surname {dataDisplay.details.surname}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PdfLayout;
