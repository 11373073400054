import React, { useContext, useEffect, useState } from "react";
import Nav from "./components/Nav";
import DashMenu from "./components/DashMenu";
import Content from "./components/Content";
import Select from "react-select";
import { type } from "@testing-library/user-event/dist/type";
// import { FileUploader } from "react-drag-drop-files";
import * as XLSX from "xlsx";
import * as Papa from "papaparse";
import axios from "axios";
import { keyBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { BatchResults } from "../context/BatchResults";
import { ActivityLoader } from "./components/ActivityLoader";
import moment from "moment";
import { PiX } from "react-icons/pi";

// import * as fs from 'fs';

const countries = [
  { value: "ZW", label: "Zimbabwe", id_placeholder: "42-251109-S-07" },
  // { value: 'ZM', label: 'Zambia', id_placeholder: '72251109/S07/20' },
  // { value: 'BW', label: 'Botswana', id_placeholder: 'BW24985998-8594' },
  // { value: 'NM', label: 'Namibia', id_placeholder: '43578/54 - 53' },
  // { value: 'ZA', label: 'South Africa', id_placeholder: '597896/85/20' }
];

const BatchIDs = () => {
  const { batchResults, setBatchResults } = useContext(BatchResults);

  const navigate = useNavigate();
  const [idvError, setIdvError] = useState();
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [hasToken, setHasToken] = useState();
  const [hasFile, setHasFile] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [dataFile, setDataFile] = useState([]);
  const [checkResult, setCheckResult] = useState([]);
  const [mismatchKey, setMismatchKey] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [failedCheck, setFailedCheck] = useState([]);
  const [passCheck, setPassCheck] = useState([]);
  let passArr = [];
  let failedArr = [];

  useEffect(() => {
    kycLogin();
  }, []);

  const kycLogin = (e) => {
    setSelectedCountry(e);

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`https://verify.kycafrica.com/api/auth/token?username=${"KYCAFRICA"}&password=${"qwertyui"}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setHasToken(JSON.parse(result));
      });
  };

  function checkSimilarity(obj1, obj2, passArr, failedArr) {
    // Check if all keys and values are the same in both objects

    for (let key in obj1) {
      console.log(obj1[key], obj2[key]);
      if (obj1[key] !== obj2[key]) {
        // console.log("fail");
        failedArr.push(obj2);
        setFailedCheck(failedArr);
        // console.log("fail", failedArr);
        return failedArr;
      }
    }
    // console.log("pass");
    passArr.push(obj1);
    setPassCheck(passArr);
  }

  //
  // setCheckResults(checkResult)

  //hand uploaded file
  const handleFileUpload = (e) => {
    setIdvError();
    setHasFile();
    const file = e.target.files[0];
    setHasFile(file);
    const fileName = file.name;
    setFileName(fileName);
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

    if (fileType === "xlsx") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // get the first sheet
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setDataFile(jsonData);
        console.log("this XLSX code ran");
      };
      reader.readAsBinaryString(file);
    } else if (fileType === "csv") {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          setDataFile(result.data);

          // console.log("this CSV code ran")
        },
      });
    } else if (fileType === "json") {
      // console.log("this JSON code ran")
      console.log(file);
      fetch(file.name)
        .then((response) => response.json())
        .then((json) => setDataFile(json))
        .catch((error) => console.error(error));
    } else {
      return console.log("unsupported");
    }
  };
  console.log(dataFile);
  //hand uploaded file change
  const objectArray = dataFile.map((item) => {
    // console.log(item.D);
    const obj = {
      personNo: item.NationalID.toLowerCase(),
      firstName: item.Firstname.toLowerCase(),
      surname: item.Surname.toLowerCase(),
      dateOfBirth: item.DateofBirth.toLowerCase(),
      sex: item.Sex.toLowerCase(),
    };
    return obj;
  });

  console.log("New Array Data", objectArray);
  console.log("New Array Data length is > ", objectArray?.length);

  const handleVerification = (e) => {
    setIdvError();
    e.preventDefault();
    setIsLoading(true);
    if (objectArray.length === 0) {
      setIdvError({ error: "Please upload a batch id file and try again" });
      setIsLoading(false);
      return;
    }

    console.log("inside handle verification", isLoading);

    objectArray.forEach((item) => {
      // console.log("hi");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${hasToken.access_token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`https://verify.kycafrica.com/api/verifyid/${item.personNo}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let feedback = JSON.parse(result);
          const resultID = {
            // personNo: feedback.personNo.toLowerCase(),
            firstName: feedback.firstName.toLowerCase(),
            surname: feedback.surname.toLowerCase(),
            dateOfBirth: feedback.dateOfBirth.toLowerCase(),
            sex: feedback.sex.toLowerCase(),
          };

          console.log("resultID", resultID);
          console.log("item", item, "resultID", resultID);
          checkSimilarity(resultID, item, passArr, failedArr);
          console.log("passed here", passArr);
          const resulted = [passArr, ...failedArr];
          setCheckResult(resulted);

          //   console.log(passArr);
          //   setBatchResults([passArr, failedArr]);
          //   console.log(failedArr);

          if (objectArray?.length === passArr?.length + failedArr.length) {
            setIsLoading(false);
            console.log(isLoading);
          } else {
            setIsLoading(true);
          }
        })

        .catch((error) => {
          console.log("error", error);
          setIsLoading(false);
        });
    });

    // console.log(resultSaved);
  };
  //   const handleSampleDownload = () => {
  //     const link = document.createElement("a");
  //     link.href = "../sample.xlsx";
  //     link.download = "sample.xlsx";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };
  const handleFailedBatch = () => {
    setBatchResults([]);
    setBatchResults([passCheck, failedCheck]);
    navigate("/BatchResults");
  };
  //   const handleDownload = () => {
  //     // Construct the URL to the file
  //     const fileUrl = `${process.env.PUBLIC_URL}/sample.xlsx`;

  //     // Create a link element
  //     const link = document.createElement("a");
  //     link.href = fileUrl;
  //     link.setAttribute("download", "sample.xlsx");

  //     // Simulate a click event to trigger the download
  //     document.body.appendChild(link);
  //     link.click();

  //     // Clean up
  //     document.body.removeChild(link);
  //   };

  // console.log(batchResults[0]?.length + batchResults[1]?.length)
  // console.log(batchResults[1]?.length)
  // console.log(batchResults[0]?.length)

  // console.log('In Context', batchResults)
  // console.log('In Failed', failedCheck)
  // console.log('In Pass', passCheck)

  return (
    <div>
      {idvError && (
        <div className="fixed  top-0 left-0 right-0 bg-red-100 flex px-10 h-20 z-20">
          <div className="flex-1 flex justify-center items-center">
            <p className=" text-red-500">{idvError.error}</p>
          </div>
          <button
            className=" text-slate-900 text-xl"
            onClick={() => {
              setIdvError();
            }}
          >
            <PiX />
          </button>
        </div>
      )}
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle="Settings" />
        <Content>
          <div className="mt-5 flex justify-between">
            <div className="w-[48%]">
              <h1 className="text-sm font-semibold mt-[2%]">Batch ID Verification</h1>
              {/* <p className=" mt-[2%]">KYC ID verification service that enables you to authenticate IDs in only a few seconds.

                            </p> */}
              <div className=" mt-[3%] pt-[3%]">
                <h5 className=" mb-[2%] text-sm   text-[#2FBF00]">Select the country of origin for the IDs you want to verify</h5>
                <Select
                  defaultValue={countries[0]}
                  options={countries}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  isRtl={isRtl}
                  isSearchable={isSearchable}
                  name="countries"
                  className=" outline-[#2FBF00]"
                  onChange={(e) => kycLogin(e)}
                  theme={(theme, state) => ({
                    ...theme,
                    ...state,
                    borderRadius: 3,

                    colors: {
                      ...theme.colors,
                      primary25: "#ccedc1",
                      primary: "#2FBF00",
                    },
                  })}
                />
                <form className=" mt-[3%]">
                  {/* <h5 className=" mt-[4%] mb-[2%] text-sm  text-[#2FBF00]  ">To verify a single ID please enter the ID No. in the format provided below</h5> */}
                  {/* <input
                                        className=" mb-[2%] bg-transparent pl-3 w-full p-2 outline-[0.5px] outline-[#2FBF00] border-[1px] border-[#E0E0E0]  rounded-[4px] "
                                        placeholder={selectedCountry?.id_placeholder}

                                        type='text'
                                        onChange={(e) => {
                                            setData((e.target.value).replace(/[^a-zA-Z0-9]/g, "").toUpperCase()
                                            )
                                            console.log(data)

                                        }}
                                    /> */}
                  {/* <FileUploader
                                        multiple={true}
                                        handleChange={(e) => {
                                            setData((e.target.value).replace(/[^a-zA-Z0-9]/g, "").toUpperCase()
                                            )
                                            console.log(data)

                                        }}
                                        name="file"
                                        types={["CSV", "JSON"]}
                                        className="relative overflow-hidden w-[100%] flex justify-center items-center py-[5%] rounded-[4px] border-dotted border-[1px] border-gray-300 mb-[5%] "
                                    /> */}
                  <h5 className=" mt-[2%] mb-[2%] text-sm  text-[#2FBF00]  ">
                    To verify multiple IDs please Upload a <tt className="text-black text-[15px]">.xlsx , .csv or .json file </tt>{" "}
                    using the instructions described below
                  </h5>
                  <div className="relative overflow-hidden w-[100%] flex justify-between items-center px-[3%] py-[0.5%] rounded-[4px] border-dotted border-[1px] border-gray-300 mb-[5%] ">
                    <button className="mb-[2%] mt-[2%] cursor-pointer text-[#2FBF00] hover:text-[#fff] hover:bg-[#2FBF00] bg-[#fff] px-[7%] py-[1%] rounded-[4px] border-solid border-[1px] border-[#2FBF00] ">
                      Upload a file
                    </button>
                    {hasFile ? (
                      <p className="text-gray-600 text-sm">...{fileName}</p>
                    ) : (
                      <p className="text-gray-600 text-sm">Please upload a file</p>
                    )}

                    <input
                      type="file"
                      accept=".csv,.json,.xlsx"
                      onChange={(e) => {
                        setIdvError();
                        handleFileUpload(e);
                      }}
                      className="absolute left-0 top-0 opacity-0 cursor-pointer
                                              bg-transparent pl-3 w-full p-2 outline-[0.5px] outline-[#2FBF00] border-[1px] border-[#E0E0E0]  rounded-[4px] "
                    />
                  </div>

                  <input
                    type="submit"
                    onClick={(e) => {
                      handleVerification(e);
                    }}
                    disabled={isLoading}
                    value={isLoading ? "Verifying..." : "Verify IDs"}
                    className={`mb-[2%] mt-[2%] block cursor-pointer text-[#fff] bg-[#2FBF00] hover:bg-[#248f00] px-[7%] py-[1%] rounded-[4px] disabled:bg-slate-200 disabled:cursor-not-allowed `}
                  />
                </form>
                <div className="mt-[4%] text-sm">
                  <h1 className="uppercase mb-[1%] text-gray-600 font-semibold">How to do a multiple ID Check?</h1>
                  <ol className="list-decimal pl-5 gap-y-3 flex flex-col ">
                    <li>
                      <p className="text-gray-600">
                        Create an excel sheet with the ID numbers you want to check in the format provided{" "}
                        <a
                          href={
                            "https://drive.usercontent.google.com/download?id=1mPMWvfROBCJBvpmUiiT2H6xg_3SADGYy&export=download&authuser=0&confirm=t&uuid=9b066713-c275-457c-860e-9d9a62766cb7&at=APZUnTXcNrhE4SFlbjJHNIZbhuSo:1711219821626"
                          }
                          target="_blank"
                          className="text-[#2FBF00] cursor-pointer"
                        >
                          here
                        </a>{" "}
                        and upload it
                      </p>
                    </li>
                    <li>
                      <p className="text-gray-600">Please make sure that all the fields are in text format</p>
                    </li>
                    <li>
                      <p className="text-gray-600">
                        in the dateOfBirth field please use the following format:{" "}
                        <span className="font-bold text-green-600">YYYY-MM-DD</span>
                      </p>
                    </li>
                    <li>
                      <p className="text-gray-600">This search can only return ID numbers that fail the check as a csv file</p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="w-[48%] h-[80vh] rounded-[4px] bg-[#efefef] pt-[1%] overflow-clip">
              <div className="flex  flex-col items-center ">
                {hasToken ? (
                  <tt className="mt-[2%] mb-[2%] text-sm text-center capitalize  text-[#2FBF00]">Logged in to the server</tt>
                ) : (
                  <tt className="mt-[2%] mb-[2%] text-sm  text-[#FF0000]">Offline: Logging in ...</tt>
                )}
                <div className="h-[40vh]">
                  {failedCheck?.length > 0 || passCheck?.length > 0 ? (
                    !isLoading ? (
                      <div className=" flex-col items-center">
                        <p className="text-center text-[#000000] font-semibold mb-[3%]">Recent Check Results</p>
                        <div className="w-[100%] pt-[%] ">
                          <div className="grid grid-cols-3 gap-10  ">
                            <p className="text-[#4f4f4f] text-right col-span-2"> Total ID Checks :</p>{" "}
                            <p className="text-[#4f4f4f] font-semibold"> {failedCheck?.length + passCheck?.length} </p>
                          </div>
                          <div className="grid grid-cols-3 gri gap-10">
                            <p className=" text-right col-span-2 text-red-500"> Failed ID Check Total:</p>{" "}
                            <p className=" text-red-500 font-semibold"> {failedCheck?.length}</p>
                          </div>
                          <div className="grid grid-cols-3 gap-10">
                            <p className="text-[#4f4f4f] text-right col-span-2"> Passed ID Check Total:</p>{" "}
                            <p className="text-[#4f4f4f] font-semibold"> {passCheck?.length}</p>
                          </div>
                        </div>
                        <button
                          onClick={handleFailedBatch}
                          className="mb-[2%] mt-[15%] cursor-pointer text-[#fff] bg-[#2FBF00] w-[100%] text-sm items-center px-[7%] py-[1%] rounded-[4px] "
                        >
                          Show Results
                        </button>
                        {/* <button className="mb-[2%] mt-[2%] cursor-pointer text-[#fff] bg-[#2FBF00] w-[100%] text-sm items-center px-[7%] py-[1%] rounded-[4px] " >Print Result</button> */}
                      </div>
                    ) : (
                      <div className="mt-[40%] text-center text-sm">
                        <ActivityLoader />
                        <p>Please Wait...</p>
                      </div>
                    )
                  ) : (
                    <p className="text-center text-[#4f4f4f]">Your check results will appear here</p>
                  )}
                </div>
                <div className=" bg-gray-600 h-[1px] w-[80%] "></div>
                <div className=" flex-col items-center pt-[3%]">
                  <p className="text-center text-[#000000] font-semibold mb-[3%]">Previous Check Results</p>
                  {batchResults[0]?.length > 0 && batchResults[1]?.length > 0 ? (
                    <div className="w-[100%]  ">
                      <div className="grid grid-cols-3 gap-10  ">
                        <p className="text-[#4f4f4f] text-right col-span-2"> Total ID Checks :</p>{" "}
                        <p className="text-[#4f4f4f] font-semibold"> {batchResults[0]?.length + batchResults[1]?.length} </p>
                      </div>
                      <div className="grid grid-cols-3 gap-10">
                        <p className="text-[#4f4f4f] text-right col-span-2"> Failed ID Check Total:</p>{" "}
                        <p className="text-[#4f4f4f] font-semibold"> {batchResults[1]?.length}</p>
                      </div>
                      <div className="grid grid-cols-3 gap-10">
                        <p className="text-[#4f4f4f] text-right col-span-2"> Passed ID Check Total:</p>{" "}
                        <p className="text-[#4f4f4f] font-semibold"> {batchResults[0]?.length}</p>
                      </div>
                      <button
                        onClick={() => {
                          navigate("/BatchResults");
                        }}
                        className="mb-[2%] mt-[15%] cursor-pointer text-[#fff] bg-[#2FBF00] w-[100%] text-sm items-center px-[7%] py-[1%] rounded-[4px] "
                      >
                        View These Results
                      </button>
                    </div>
                  ) : (
                    <p className="text-center text-[#4f4f4f]">Your previous check results will appear here</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
};
export default BatchIDs;
