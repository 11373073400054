import React from "react";
import { useNavigate } from "react-router-dom";

export default function GroupedCard({ i }) {
  const navigate = useNavigate();
  console.log(i);

  const handleClick = (item) => {
    let title = item.home ? item?.home?.streetName : item?.work?.streetName;
    navigate(`/Reports/Logs/${item.user._id}/${title}`);
  };

  return (
    <div
      className="bg-[#00000009] hover:bg-[#00000013] items-center flex overflow-clip rounded h-[80px] mb-3 cursor-pointer"
      onClick={() => handleClick(i)}
      key={i._id}
    >
      {i.home ? (
        <div className="flex items-center">
          <div
            className="bg-red-400 w-5 h-[80px]"
            style={{
              backgroundColor:
                i.home.homeVerified === "pending"
                  ? "#FF8015"
                  : i.home.homeVerified === "success"
                  ? "#2FBF00"
                  : "#FF0000",
            }}
          ></div>

          <p className=" text-sm pr-4 pl-4">Location Address:</p>
          <p className="capitalize  font-semibold">
            {i.home?.houseNo + " " + i.home?.streetName + "," + " " + i.home?.city}
          </p>
        </div>
      ) : (
        <div className="flex items-center">
          <div
            className="bg-red-400 w-5 h-[80px]"
            style={{
              backgroundColor:
                i.work && i.work.workVerified === "pending"
                  ? "#FF8015"
                  : i.work && i.work.workVerified === "success"
                  ? "#2FBF00"
                  : "#FF0000",
            }}
          ></div>

          <p className=" text-sm pr-4 pl-4">Location Address:</p>
          <p className="capitalize  font-semibold">
            {i.work?.building + " " + i.work?.streetName + "," + " " + i.work?.city}
          </p>
        </div>
      )}

      {/* <div>
        <p className=" text-sm">Checked on:</p>
        <p className="capitalize  font-semibold">{moment(i.createdAt).format("lll")}</p>
      </div> */}

      {/* <div className="flex flex-col">
        <p className=" text-sm">Location GPS Cordinates:</p>
        <div className=" flex gap-5">
          <p className="capitalize hover:underline hover:text-[#2FBF00] font-semibold">
            <a href={`https://www.google.com/maps/?q=${location.latitude},${location.longitude}`} target="_blank">
              {" "}
              Lat:{location.latitude}
              {" : "} Lng:{location.longitude}
            </a>
          </p>
        </div>
      </div> */}
    </div>
  );
}
