import React, { useContext, useState } from "react";
import api_icon_b from "../../assets/api-black.svg";
import api_icon_w from "../../assets/api.svg";
import dash_icon_b from "../../assets/dash_b.svg";
import dash_icon_w from "../../assets/dash_w.svg";
import logout_icon from "../../assets/log-out.svg";
import reports_icon_b from "../../assets/reports_b.svg";
import reports_icon_w from "../../assets/reports_w.svg";
import settings_icon_b from "../../assets/settings_b.svg";
import settings_icon_w from "../../assets/settings_w.svg";
import users_icon_b from "../../assets/users_b.svg";
import users_icon_w from "../../assets/users_w.svg";

import { HeartIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const DashMenu = ({ pageTitle }) => {
  const navigate = useNavigate();
  let location = useLocation();
  const { user, setUser } = useContext(UserContext);

  const { isSuper } = user;
  console.log(isSuper);

  const isSuperMenu = [
    {
      id: 1,
      icon_b: dash_icon_b,
      icon_w: dash_icon_w,
      name: "Dashboard",
      to: "/",
      role: "admin",
    },
    {
      id: 2,
      icon_b: users_icon_b,
      icon_w: users_icon_w,
      name: "Users",
      to: "/Users",
      role: ["admin"],
    },
    {
      id: 3,
      icon_b: reports_icon_b,
      icon_w: reports_icon_w,
      name: "Verification Logs",
      to: "/Reports",
      role: ["admin"],
    },
    {
      id: 4,
      icon_b: api_icon_b,
      icon_w: api_icon_w,
      name: "ID Verification",
      to: "/Verification",
      role: ["admin", "all"],
    },
    {
      id: 7,
      icon_b: api_icon_b,
      icon_w: api_icon_w,
      name: "Batch ID Verification",
      to: "/BatchVerification",
      role: ["admin", "all"],
    },
    {
      id: 5,
      icon_b: users_icon_b,
      icon_w: users_icon_w,
      name: "System Administration",
      to: "/Administrators",
      role: ["admin"],
    },
    {
      id: 6,
      icon_b: settings_icon_b,
      icon_w: settings_icon_w,
      name: "Security",
      to: "/Settings",
      role: ["admin"],
    },
  ];

  const nonSuperMenu = [
    {
      id: 4,
      icon_b: api_icon_b,
      icon_w: api_icon_w,
      name: "ID Verification",
      to: "/Verification",
      role: ["admin", "all"],
    },
    {
      id: 7,
      icon_b: api_icon_b,
      icon_w: api_icon_w,
      name: "Batch ID Verification",
      to: "/BatchVerification",
      role: ["admin", "all"],
    },
  ];

  const handleClick = (item) => {
    navigate(item.to);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.clear();
  };

  return (
    <div className="w-3/12 flex-col h-full flex justify-between items-center pb-5 pt-16">
      <div className="flex flex-col gap-3 classname= w-9/12">
        {isSuper
          ? isSuperMenu.map((item) => {
              return (
                <button
                  key={item.id}
                  onClick={() => {
                    handleClick(item);
                  }}
                  className={`flex rounded-md h-10  items-center pl-10 pr-15 hover:scale-[102%] 
                  `}
                  style={{
                    backgroundColor: `${item.to}` === location?.pathname ? "#2FBF00" : "#D9D9D9",
                  }}
                >
                  <img src={`${item.to}` === location?.pathname ? item.icon_w : item.icon_b} />
                  <span
                    className="text-xs ml-3"
                    style={{ color: `${item.to}` === location?.pathname ? "#FFFFFF" : "#000000" }}
                  >
                    {item.name}
                  </span>
                </button>
              );
            })
          : nonSuperMenu.map((item) => {
              return (
                <button
                  key={item.id}
                  onClick={() => {
                    handleClick(item);
                  }}
                  className={`flex rounded-md h-10  items-center pl-10 pr-15 hover:scale-[102%]`}
                  style={{
                    backgroundColor: `${item.to}` === location?.pathname ? "#2FBF00" : "#D9D9D9",
                  }}
                >
                  <img src={`${item.to}` === location?.pathname ? item.icon_w : item.icon_b} />
                  <span
                    className="text-xs ml-3"
                    style={{ color: `${item.to}` === location?.pathname ? "#FFFFFF" : "#000000" }}
                  >
                    {item.name}
                  </span>
                </button>
              );
            })}
      </div>

      <div className="h-200 w-9/12">
        <p className="text-xs font-light text-gray-400 mb-5">
          Please note that for security reasons you will be logged out automatically by the system
          after 10 mins of inactivity.{" "}
        </p>
        <button
          className="flex bg-gray-600 rounded-md gap-2 pl-3 pr-3  cursor-pointer hover:bg-[#2FBF00] text-[#FFF] p-2"
          onClick={() => handleLogout()}
        >
          <p className="capitalize">logout</p>
          <img src={logout_icon} />{" "}
        </button>
      </div>
    </div>
  );
};
export default DashMenu;
