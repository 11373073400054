import React, { useContext, useEffect, useRef, useState } from "react";
import InnerHeader from "./InnerHeader";
import Nav from "../components/Nav";
import DashMenu from "../components/DashMenu";
import Content from "../components/Content";
import { Link, unstable_HistoryRouter, useLocation, useNavigate } from "react-router-dom";
import { ActivityLoader } from "./ActivityLoader";
import moment from "moment";
import QRCode from "react-qr-code";
import { PrintContext } from "../../context/PrintContext";
import { useReactToPrint } from "react-to-print";
import { ModalContext } from "../../context/ModalContext";
import Modal from "./Modal";

const UserDetails = () => {
  const { setPrintData } = useContext(PrintContext);

  const { setModalContent } = useContext(ModalContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [popupModal, setPopupModal] = useState(false);
  const [clientHomeLocations, setClientHomeLocations] = useState([]);
  const [clientWorkLocations, setClientWorkLocations] = useState([]);

  const location = useLocation();
  const details = location.state.user;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp-data",
    // onAfterPrint: () => alert("Print successful"),
  });

  // getting single user details based on id
  const gatheringAllUserLocations = () => {
    setLoading(true);
    let fetchOk = (...args) =>
      fetch(...args).then((res) =>
        res.ok
          ? res
          : res.json().then((data) => {
              throw Object.assign(new Error(data.error_message), { name: res.statusText });
            })
      );
    Promise.all(
      [
        `https://kycbackendapp.onrender.com/api/home/my/${details._id}`,
        `https://kycbackendapp.onrender.com/api/work/my/${details._id}`,
      ].map((url) => fetchOk(url).then((r) => r.json()))
    )
      .then(([d1, d2]) => {
        setClientHomeLocations(d1);
        setClientWorkLocations(d2);

        // logging print info into the context
        setPrintData([
          {
            details: details,
            home: d1,
            work: d2,
          },
        ]);

        setLoading(false);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    gatheringAllUserLocations();
  }, []);

  const handViewOnMapHome = (homeDetails) => {
    let LocationToViewH = JSON.parse(homeDetails?.homeLatLng);
    // locationLink = `${LocationToViewH.lat},${LocationToViewH.lng}`? "":
  };
  const handViewOnMapWork = (homeDetails) => {
    let LocationToViewH = JSON.parse(homeDetails?.homeLatLng);
    // locationLink = `${LocationToViewH.lat},${LocationToViewH.lng}`? "":
  };

  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle={"Users"} />

        <Content>
          {loading ? (
            <ActivityLoader message="Please wait..." />
          ) : (
            <>
              <InnerHeader
                onClick={() => {
                  navigate(`/User/data/print-preview`, { state: { user: details } });
                }}
                onClickDelete={() => {
                  setModalContent(details);
                  setPopupModal(true);
                }}
                // handlePrint={handlePrint}
                data={[clientHomeLocations, clientWorkLocations, details]}
                SectionHeader={`Verification Details for ${details.firstname} ${details.surname}`}
                SectionDescription={
                  <p>
                    Showing all registered details for {details.firstname} {details.surname}{" "}
                  </p>
                }
                setcheckBoxVisible={"setcheckBoxVisible"}
                checkBoxVisible={"checkBoxVisible"}
                menu={"menu"}
                hidden={true}
                hidden1={true}
                hidden2={true}
                hidePrint={true}
                hideDelete={false}
              />
              {location && (
                <div className=" w-full overflow-auto">
                  <div ref={componentRef} className=" w-full   pl-8 pr-8 " style={{ height: `calc(100vh - 200px)` }}>
                    <div className="bg- pb-5 pt-5 border-b border-dotted border-gray-900">
                      <div className="">
                        <div>
                          <h1 className="font-medium text-lg pb-2 text-[#2FBF00]">Personal Details</h1>
                        </div>
                        <div className="flex justify-between  ">
                          <div className=" w-6/12">
                            <div className="grid grid-cols-2 w-full mb-4">
                              <h1 className="capitalize font-semibold">
                                <span className="font-light"> Name(s):</span> <br />
                                {details.firstname}
                              </h1>
                              <h1 className="capitalize font-semibold">
                                <span className="font-light">Surname: </span> <br />
                                {details.surname}
                              </h1>
                            </div>
                            <div className="grid grid-cols-2 w-full ">
                              <h1 className="font-semibold">
                                <span className="font-light">National ID No.:</span>
                                <br /> {details.idNumber.toUpperCase()}
                              </h1>
                              <h1 className="font-semibold">
                                <span className="font-light">Phone Number:</span>
                                <br /> {details.phone}
                              </h1>
                            </div>
                            <div className=" pb-5 pt-5  ">
                              <h1>Registered on: {moment(details.createdAt).format("lll")}</h1>
                              <p className="text-xs italic mt-2 text-gray-500">
                                This is the date when {details.firstname} {details.surname} registered on the KYC Africa National
                                Identity & Address Verification Platform / System.
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <QRCode value={`KYCAID_${details._id}`} size={160} />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* displaying all locations for client */}
                    <div className="  pt-5 w-full">
                      <div className="pb-5 flex-1 border-b border-dotted border-gray-900">
                        <h4 className="font-medium text-lg pb-2 text-[#2FBF00]">
                          <span className="capitalize">{details.firstname}</span> 's Home Locations
                        </h4>
                        <div className="flex flex-wrap w-full">
                          {clientHomeLocations.length > 0 ? (
                            <div className="flex flex-col w-full ">
                              {clientHomeLocations.map((homeDetails) => (
                                <div className="mb-5 flex    ">
                                  <div
                                    className=" w-5 rounded-l-md h-full "
                                    style={{
                                      backgroundColor:
                                        homeDetails.homeVerified === "pending"
                                          ? "#FF8015"
                                          : homeDetails.homeVerified === "success"
                                          ? "#2FBF00"
                                          : "#FF0000",
                                    }}
                                  ></div>
                                  <div className=" pl-5 pt-2 pb-2 flex justify-between pr-10 items-center rounded-r-md w-full bg-[#00000009]">
                                    <div className="">
                                      <p className="capitalize font-semibold">
                                        <div></div>
                                        <span className="font-light">Address:</span>{" "}
                                        {homeDetails.houseNo + " " + homeDetails.streetName}
                                      </p>
                                      <p className="capitalize font-semibold">
                                        <span className="font-light">Surburb:</span> {homeDetails.suburb}
                                      </p>
                                      <p className="capitalize font-semibold">
                                        <span className="font-light">City:</span> {homeDetails.city}
                                      </p>
                                      <p className="capitalize font-semibold">
                                        <span className="font-light">added on:</span>{" "}
                                        {moment(homeDetails.createdAt).format("lll")}
                                      </p>
                                      <p
                                        className="capitalize font-semibold"
                                        style={{
                                          color:
                                            homeDetails.homeVerified === "pending"
                                              ? "#FF8015"
                                              : homeDetails.homeVerified === "success"
                                              ? "#2FBF00"
                                              : "#FF0000",
                                        }}
                                      >
                                        <span className="font-light text-black">Verification state:</span>{" "}
                                        {homeDetails.homeVerified}
                                      </p>
                                      <p className="capitalize font-semibold">
                                        <span className="font-light">Positive Verification Checks:</span>{" "}
                                        {homeDetails.homeVerificationCount}/20
                                      </p>
                                      <p className="capitalize font-semibold">
                                        <span className="font-light">Total Verification Checks:</span>{" "}
                                        {homeDetails.homeTotalCount}/20
                                      </p>
                                    </div>
                                    {/* <div>
                                      <a
                                        onClick={() => {
                                          let cordinates = JSON.parse(homeDetails.homeLatLng);
                                          console.log(cordinates.lat);
                                          const linkToMap =
                                            "https://www.google.com/maps/?q=" + cordinates?.lat + "," + cordinates?.lng;
                                        }}
                                        className="capitalize p-2 bg-[#2FBF30] hover:scale-[101%]  text-white text-xs rounded-md cursor-pointer "
                                      >
                                        View Location on Google maps
                                      </a>
                                    </div> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <>
                              <p className="italic text-gray-500">
                                <span className="capitalize ">{details.firstname}</span> has not set any home locations
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* displaying all locations for client */}
                </div>
              )}
            </>
          )}
        </Content>
      </div>
      {popupModal ? <Modal setPopupModal={setPopupModal} hasRedirect /> : null}
    </div>
  );
};

export default UserDetails;
