import React, { useEffect, useState } from "react";
import Nav from "./components/Nav";
import DashMenu from "./components/DashMenu";
import Content from "./components/Content";
import { ActivityLoader } from "./components/ActivityLoader";
import StatCard from "./components/StatCard";
import moment from "moment/moment";
// import { verifiedHome, verifiedWork, pendingHome, pendingWork } from "./components/SortedUsers";

import { useLocation, useNavigate } from "react-router-dom";
import DashChart from "./components/DashChart";

export const Home = () => {
  // let useIDNo = '42-2343546 s 07'
  // const result = useIDNo.replace(/[^a-zA-Z0-9]/g, '')

  // console.log('====================================');
  // console.log(result.toUpperCase());
  // console.log('====================================');

  const [loading, setLoading] = useState(false);
  const [allHomeData, setAllHomeData] = useState([]);
  const [allWorkData, setAllWorkData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [allAdminData, setAllAdminData] = useState([]);

  // getting single user details based on id
  const getAllData = () => {
    setLoading(true);
    let fetchOk = (...args) =>
      fetch(...args).then((res) =>
        res.ok
          ? res
          : res.json().then((data) => {
              throw Object.assign(new Error(data.error_message), { name: res.statusText });
            })
      );

    Promise.all(
      [
        `https://kycbackendapp.onrender.com/api/home/`,
        `https://kycbackendapp.onrender.com/api/work/`,
        `https://kycbackendapp.onrender.com/api/user`,
        `https://kycbackendapp.onrender.com/api/admin`,
      ].map((url) => fetchOk(url).then((r) => r.json()))
    )
      .then(([d1, d2, d3, d4]) => {
        setAllHomeData(d1);
        setAllWorkData(d2);
        setAllUserData(d3);
        setAllAdminData(d4);
        setLoading(false);
      })
      .catch((e) => console.error(e));
  };
  const allData = [...allHomeData, ...allWorkData];
  //filtered addresses

  const verifiedHome = allHomeData.filter((i) => {
    return i.homeVerified === "success";
  });
  // const verifiedWork = allWorkData.filter((i) => {
  //   return i.workVerified === "success";
  // });
  const pendingHome = allHomeData.filter((i) => {
    return i.homeVerified === "pending";
  });
  // const pendingWork = allWorkData.filter((i) => {
  //   return i.workVerified === "pending";
  // });
  const unverifiedHome = allHomeData.filter((i) => {
    return i.homeVerified !== "success";
  });
  const unverifiedWork = allWorkData.filter((i) => {
    return i.workVerified !== "success";
  });

  // console.log(' hreeeeeeeeee');

  const dataJan = allData?.filter((i) => {
    return moment(i?.createdAt).month() === 0;
  });

  const dataFeb = allData.filter((i) => {
    return moment(i.createdAt).month() === 1;
  });
  // console.log()

  const dataMar = allData.filter((i) => {
    return moment(i.createdAt).month() === 2;
  });
  const dataApr = allData.filter((i) => {
    return moment(i.createdAt).month() === 3;
  });
  const dataMay = allData.filter((i) => {
    return moment(i.createdAt).month() === 4;
  });
  const dataJun = allData.filter((i) => {
    return moment(i.createdAt).month() === 5;
  });
  const dataJul = allData.filter((i) => {
    return moment(i.createdAt).month() === 6;
  });
  const dataAug = allData.filter((i) => {
    return moment(i.createdAt).month() === 7;
  });
  const dataSep = allData.filter((i) => {
    return moment(i.createdAt).month() === 8;
  });
  const dataOct = allData.filter((i) => {
    return moment(i.createdAt).month() === 9;
  });
  const dataNov = allData.filter((i) => {
    return moment(i.createdAt).month() === 10;
  });
  const dataDec = allData.filter((i) => {
    return moment(i.createdAt).month() === 11;
  });

  const dataByMonth = [
    dataJan,
    dataFeb,
    dataMar,
    dataApr,
    dataMay,
    dataJun,
    dataJul,
    dataAug,
    dataSep,
    dataOct,
    dataNov,
    dataDec,
  ];

  // console.log('=====================')
  // console.log(dataByMonth)
  // console.log('=====================')

  useEffect(() => {
    getAllData();
  }, []);

  const navigate = useNavigate();
  let location = useLocation();

  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle={"Dashboard"} />
        <Content>
          {loading ? (
            <ActivityLoader message="Please wait fetching data...." />
          ) : (
            <div className=" pt-3 overflow-auto" style={{ height: `calc(100vh - 100px)` }}>
              <div className="grid grid-cols-4">
                <StatCard
                  handleClick={() => {
                    navigate("/Users");
                  }}
                  statNumber={allUserData.length}
                  title="Registered App Users"
                  description="A total of all app users. This does not account for their activity status"
                />
                <StatCard
                  handleClick={() => {
                    navigate("/Users");
                  }}
                  statNumber={allHomeData.length}
                  title="Total Home Addresses"
                  description="A total of all home addresses registered by all users"
                />

                <StatCard
                  handleClick={() => {
                    navigate("/Users");
                  }}
                  statNumber={pendingHome.length}
                  title="Pending Home Addresses"
                  description="A total of all home addresses pending verification"
                />
                <StatCard
                  handleClick={() => {
                    navigate("/Users");
                  }}
                  statNumber={verifiedHome.length}
                  title="Verified Home Addresses"
                  description="A total of all verified home addresses registered by all users"
                />
              </div>
              <div className="grid mt-5 grid-cols-4">
                <StatCard
                  handleClick={() => {
                    navigate("/Administrators");
                  }}
                  statNumber={allAdminData.length}
                  title="Administrators"
                  description="A total of administrators for the KYC Africa address verification application"
                />
                {/* <StatCard
                  handleClick={() => {
                    navigate("/Users");
                  }}
                  statNumber={allWorkData.length}
                  title="Total Work Addresses"
                  description="A total of all work addresses registered by all users"
                />





                <StatCard
                  handleClick={() => {
                    navigate("/users");
                  }}
                  statNumber={pendingWork.length}
                  title="Pending Work Addresses"
                  description="A total of all work addresses pending verification"
                />
                <StatCard
                  handleClick={() => {
                    navigate("/Users");
                  }}
                  statNumber={verifiedWork.length}
                  title="Verified Work Addresses"
                  description="A total of all verified work addresses registered by all users"
                /> */}
              </div>

              <DashChart data={dataByMonth} />
            </div>
          )}
        </Content>
      </div>
    </div>
  );
};
