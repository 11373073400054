import React, { useContext, useState } from "react";
import downArrow from "../../assets/drop-down.svg";
import delete_icon from "../../assets/delete.svg";
import print_icon from "../../assets/print.svg";
import PdfLayout from "./PdfLayout";
import back_icon from "../../assets/back_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";

const InnerHeader = ({
  data,
  handlePrint,
  SectionHeader,
  SectionDescription,
  setsortingDropdown,
  sortingDropdown,
  setcheckBoxVisible,
  checkBoxVisible,
  adminControls,
  menu,
  hidden,
  hidden1,
  hidden2,
  hidePrint,
  hideDelete,
  onClick,
  onClickDelete,
}) => {
  const [printView, setPrintView] = useState(false);
  const navigate = useNavigate();
  let Location = useLocation();

  const { adminModal, setAdminModal } = useContext(AdminContext);

  return (
    <>
      <div>
        <div className="flex h-16  items-end border-b-gray-200 border-b-[1px] pb-5 ">
          <div className="mr-20">
            <h1 className="text-sm font-semibold mt-[2%]">{SectionHeader}</h1>
            <p className="text-xs text-[#505050] capitalize">{SectionDescription}</p>
          </div>
          {setsortingDropdown ? (
            <div className="mb-[-0.3%] hover:bg-[#00000015] cursor-pointer items-center p-1 rounded-md relative">
              <button
                onClick={() => {
                  setsortingDropdown(!sortingDropdown);
                }}
                className="flex justify-start"
              >
                <div className="flex flex-col items-start">
                  <h1 className="text-xs font-semibold">Sorting by:</h1>
                  <p className="text-xs text-[#505050]">Registration date</p>
                </div>
                <div className="flex items-center justify-center ml-2  mt-2">
                  <img src={downArrow} />
                </div>
              </button>
              {sortingDropdown && (
                <div className="absolute bg-white shadow-md rounded-md p-2 w-40 mt-1 z-50">
                  <ul className=" flex flex-col gap-1">
                    {/* <li className="text-xs hover:bg-[#2FBF00] hover:text-white p-1 rounded-[3px] mb-2" value="date">
                      Registration Date
                    </li> */}
                    <li className="text-xs hover:bg-[#2FBF00] hover:text-white p-1 rounded-[3px] " value="name">
                      User Name
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : null}
          {/* dash board buttons */}
          <div className="flex flex-1 gap-5 justify-end">
            {hidden1 && (
              <button
                onClick={() => {
                  onClick();
                  // setPrintView(true);
                }}
                className="text-xs p-2 flex gap-2 items-center hover:bg-[#2f8313] cursor-pointer bg-[#2FBF00] rounded-[5px] text-white"
              >
                <img src={print_icon} />
                Print preview {menu[1].name}
              </button>
            )}
            {hidden2 && (
              <>
                {!hidePrint ? (
                  <button
                    onClick={() => {
                      // setPrintView(true);
                      handlePrint();
                    }}
                    className="text-xs p-2 flex gap-2 items-center hover:bg-[#2f8313] cursor-pointer bg-[#2FBF00] rounded-[5px] text-white"
                  >
                    <img src={print_icon} />
                    Print
                  </button>
                ) : null}
                {!hideDelete ? (
                  <button
                    onClick={onClickDelete}
                    className="text-xs p-2 items-center flex gap-2 hover:bg-red-600  cursor-pointer bg-[#FF0000] rounded-[5px] text-white"
                  >
                    <img src={delete_icon} />
                    Delete {menu[1].name}
                  </button>
                ) : null}
              </>
            )}

            {hidden && (
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="text-xs p-2 pr-3 items-center flex gap-2 hover:bg-gray-600  cursor-pointer bg-gray-500 rounded-[5px] text-white"
              >
                <img src={back_icon} className="h-4 w-4" alt={"back_icon"} />
                Back
              </button>
            )}
            {adminControls && (
              <button className="bg-[#2FBF00] text-sm   h-10 px-[4%] rounded-[4px] text-white font-medium hover:cursor-pointer hover:bg-[#27A000]" onClick={() => { setAdminModal(!adminModal) }}>{adminModal ? "Cancel" : "Create New Admin"}</button>
            )}
          </div>
        </div>
      </div>

      {printView && <PdfLayout setPrintView={setPrintView} />}
    </>
  );
};

export default InnerHeader;
