import { XMarkIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function EditModal({ data, setEditModal }) {
  //   console.log(data);
  const [userName, setUserName] = useState(data.userName);
  const [email, setEmail] = useState(data.email);
  const [phone, setPhone] = useState(data.phone);
  const [isSuper, setIsSuper] = useState(data.isSuper);
  const [error, setError] = useState();
  const [loadingAdmin, setLoadingAdmin] = useState(false);

  const Checkbox = ({ children, ...props }) => (
    <label style={{ marginRight: "1em" }}>
      <input type="checkbox" {...props} />
      {children}
    </label>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userName && !phone && !email) {
      setError("Please complete the form below");
      return;
    } else {
      setLoadingAdmin(true);
      // action
      axios
        .patch(`https://kycbackendapp.onrender.com/api/admin/${data._id}`, {
          userName: userName,
          email: email,
          phone: phone,
          isSuper: isSuper,
        })
        .then((res) => {
          setEditModal(false);
          setLoadingAdmin(false);
        })
        .catch((error) => {
          setError(error.response.data.message);
          setLoadingAdmin(false);
        });
      // action
    }
  };

  const handleDeleteAdmin = async () => {
    let text = `are you sure you want to delete ${data.userName} from the system`;
    if (window.confirm(text) == true) {
      //
      axios
        .delete(`https://kycbackendapp.onrender.com/api/admin/${data._id}`)
        .then((res) => {
          setEditModal(false);
          setLoadingAdmin(false);
        })
        .catch((error) => {
          setError(error.response.data.message);
          setLoadingAdmin(false);
        });
    }
  };

  useEffect(() => {
    setError();
    return () => {
      setError();
    };
  }, []);

  return (
    <div className="bg-white absolute top-0 left-0 right-0 bottom-0">
      <div className="flex justify-between p-4">
        <h4>Edit/Update {data.userName}</h4>
        <button onClick={() => setEditModal(false)}>
          <XMarkIcon className="h-6 w-6 text-red-500 hover:text-red-600" />
        </button>
      </div>
      <form onSubmit={handleSubmit} className="my-4 p-5 z-0">
        {error && <div className="text-red-400">{error}</div>}
        <input
          input="text"
          value={userName}
          placeholder="Enter Username"
          required
          onChange={(e) => setUserName(e.target.value)}
          className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
        />
        <input
          input="email"
          value={email}
          placeholder="Email"
          required
          onChange={(e) => setEmail(e.target.value)}
          className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
        />
        <input
          input="tel"
          value={phone}
          placeholder="Enter Phone Number"
          required
          onChange={(e) => setPhone(e.target.value)}
          className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
        />
        <div className=" mb-5">
          <Checkbox checked={isSuper} onChange={() => setIsSuper((state) => !state)}>
            <span className="ml-2">SuperAdmin</span>
          </Checkbox>
        </div>

        <input
          disabled={loadingAdmin}
          value={loadingAdmin ? " Please wait..." : `Update ${data.userName}'s Account`}
          type="submit"
          className="bg-[#2FBF00] capitalize h-10 w-full rounded-md text-white font-medium hover:cursor-pointer hover:bg-[#27A000]"
        />
      </form>
      <div className="p-4">
        <button onClick={handleDeleteAdmin} className="bg-red-500 w-full text-white p-2 rounded capitalize">
          Remove {data.userName}
        </button>
      </div>
    </div>
  );
}
