import React from "react";
import { Home } from "./Home";
import AppLogout from "./components/AppLogout";

const Dashboard = () => {
  return (
    <AppLogout>
      <Home />
    </AppLogout>
  );
};
export default Dashboard;
