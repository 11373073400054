import React, { useContext, useEffect, useState } from "react";
import Nav from "./components/Nav";
import DashMenu from "./components/DashMenu";
import Content from "./components/Content";
import InnerHeader from "./components/InnerHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { ActivityLoader } from "./components/ActivityLoader";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AdminContext } from "../context/AdminContext";
import axios from "axios";
import { Navigate } from "react-router-dom";
import EditModal from "./components/EditModal";

export const Administrators = () => {
  const [loading, setloading] = useState(false);
  const [fetchedData, setfetchedData] = useState();
  const { adminModal, setAdminModal } = useContext(AdminContext);

  // inputs section
  const [error, setError] = useState();
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSuper, setIsSuper] = useState(false);
  const [password, setPassword] = useState("");
  const [editModal, setEditModal] = useState(null);
  const [editDetails, setEditDetails] = useState();

  // https://kycbackendapp.onrender.com/api/admin/create-admin

  const fetchingData = () => {
    setloading(true);
    fetch("https://kycbackendapp.onrender.com/api/admin")
      .then((response) => response.json())
      .then((data) => {
        setfetchedData(data);
        setloading(false);
      });
  };

  useEffect(() => {
    fetchingData();
  }, [editModal]);

  const dateBodyTemplate = (data) => {
    return <p value={moment(data.createdAt).format("LLL")}>{moment(data.createdAt).format("LL")}</p>;
  };

  const superTemplate = (data) => {
    return <p>{data.isSuper ? "Yes" : "No"}</p>;
  };

  const editDataBody = (data) => {
    return (
      <button
        onClick={() => {
          setEditModal(true);
          setEditDetails(data);
        }}
        className=""
      >
        <PencilSquareIcon className="h-6 w-6 text-gray-500 hover:text-gray-600" />
      </button>
    );
  };

  const Checkbox = ({ children, ...props }) => (
    <label style={{ marginRight: "1em" }}>
      <input type="checkbox" {...props} />
      {children}
    </label>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userName && !phone && !email && !password) {
      setError("Please complete the form below");
      return;
    }

    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      userName: userName,
      email: email,
      phone: phone,
      isSuper: isSuper,
      password: password,
    });

    let reqOptions = {
      url: "https://kycbackendapp.onrender.com/api/admin/create-admin",
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };

    try {
      let res = await axios.request(reqOptions);
      window.location.reload();
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    setError();
    return () => {
      setError();
    };
  }, []);

  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle="Administrators" />
        <Content>
          <InnerHeader
            SectionHeader="System App Admins"
            SectionDescription={
              <p>
                Showing <span className="font-semibold">{fetchedData?.length}</span> admins
              </p>
            }
            hidden={false}
            hidden2={false}
            adminControls={true}
          />
          {/* table */}
          {!loading ? (
            <div className="overflow-y-scroll w-full " style={{ height: `calc(100vh - 200px)` }}>
              {/*  */}
              <DataTable
                value={fetchedData}
                paginatorPosition="bottom"
                paginator={fetchedData?.length > 10 ? true : false}
                selectionMode="single"
                rows={10}
                rowsPerPageOptions={[10]}
                totalRecords={fetchedData?.length}
                // onSelectionChange={(e) => {
                //   navigate(`//data`, { state: { user: e.value } });
                // }}
                height={400}
              >
                <Column body={dateBodyTemplate} header="Date" sortable />
                <Column field="userName" header="Name" sortable />
                <Column field="email" header="Email" sortable />
                <Column body={superTemplate} field="isSuper" header="Super Admin" sortable />
                <Column field="phone" header="Phone" sortable />
                <Column body={editDataBody} header="Edit" />
              </DataTable>
              {/*  */}
            </div>
          ) : (
            <ActivityLoader message="Fetching Your Data..." />
          )}
          {adminModal && (
            <div className="bg-[#3e4f6440] absolute top-0 bottom-0 right-0 p-3 w-full">
              <div className="bg-white rounded-l-md absolute top-0 bottom-0 right-0 p-3 w-1/2">
                <div className="flex justify-between">
                  <h4 className="ml-[4%] mt-[3%]">Create a new admin account</h4>
                  <button onClick={() => setAdminModal(false)}>
                    <XMarkIcon className="h-6 w-6 text-red-500 hover:text-red-600" />
                  </button>
                </div>
                {/* form below */}
                <form onSubmit={handleSubmit} className="my-4 p-5">
                  {error && <div className="text-red-400">{error}</div>}
                  <input
                    input="text"
                    placeholder="Enter Username"
                    required
                    onChange={(e) => setUserName(e.target.value)}
                    className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
                  />
                  <input
                    input="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
                  />
                  <input
                    input="tel"
                    placeholder="Enter Phone Number"
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
                  />
                  <div className=" mb-5">
                    <Checkbox checked={isSuper} onChange={() => setIsSuper((state) => !state)}>
                      <span className="ml-2">SuperAdmin</span>
                    </Checkbox>
                  </div>
                  <input
                    input="password"
                    placeholder="Enter Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
                  />
                  <input
                    disabled={loadingAdmin}
                    value={loadingAdmin ? " Please wait..." : "Submit"}
                    type="submit"
                    className="bg-[#2FBF00] h-10 w-full rounded-md text-white font-medium hover:cursor-pointer hover:bg-[#27A000]"
                  />
                </form>
              </div>
            </div>
          )}
          {editModal && <EditModal data={editDetails} setEditModal={setEditModal} />}
        </Content>
      </div>
    </div>
  );
};
