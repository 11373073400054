import axios from "axios";
import { useState } from "react";

export function useRequests() {
  const [allDataReports, setAllDataReports] = useState([]);
  const [allMyFetchedLogs, setAllMyFetchedLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const reporting = async () => {
    setIsLoading(true);
    axios.get(`https://kycbackendapp.onrender.com/api/report/`).then((res) => {
      const b = res.data;
      setAllDataReports(b);
      setIsLoading(false);
    });
  };

  const fetchMyLogs = async (id) => {
    setIsLoading(true);
    axios.get(`https://kycbackendapp.onrender.com/api/report/my/${id}`).then((res) => {
      const b = res.data;
      setAllMyFetchedLogs(b);
      setIsLoading(false);
    });
  };

  return { reporting, allDataReports, fetchMyLogs, allMyFetchedLogs, isLoading };
}

// fetch("https://kycbackendapp.onrender.com/api/reports")
// .then((response) => response.json())
// .then((data) => {
//   setAllReports(data);
//   setIsLoading(false);
// })
