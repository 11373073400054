import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/kyc-logo.svg";
import SearchIcon from "../../assets/icon_search.svg";
import { Link, json, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import debounce from "lodash.debounce";
import { ActivityLoader } from "./ActivityLoader";
import axios from "axios";

const getSearchedList = (query, item) => {
  // if (!query) {
  //   return item;
  // }
  return item.filter((i) => i.idNumber.includes(query));
};

// const verifiedHome = allHomeData.filter((i) => {
//   return i.homeVerified === "success";
// });

const Nav = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [visibleList, setVisibleList] = useState(false);

  // search functionality
  const [allUsers, setAllUsers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const adminUser = user.userName;

  useEffect(() => {
    getUsersList()
      .then((json) => {
        setAllUsers(json);
        return json;
      })
      .then((json) => {
        setSearchResults(json);
      });
  }, []);

  const getUsersList = async () => {
    setIsLoading(true);
    const response = await axios.get(`https://kycbackendapp.onrender.com/api/user`);
    setIsLoading(false);
    let filteredAvailableUser = response.data.filter((i) => i.hasOwnProperty("firstname"));
    return filteredAvailableUser;
  };

  const delayblur = () => {
    setTimeout(() => {
      setVisibleList(false);
    }, 200);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSearchChange = (e) => {
    setVisibleList(true);
    if (!e.target.value) return setSearchResults(allUsers);

    const resultArray = allUsers.filter(
      (user) =>
        user.firstname.toLowerCase().includes(e.target.value) ||
        user.surname.toLowerCase().includes(e.target.value) ||
        user.idNumber.toLowerCase().includes(e.target.value) ||
        user.idNumber.includes(e.target.value) ||
        user.phone.includes(e.target.value) ||
        user.phone.substring(1).includes(e.target.value.substring(1))
    );

    setSearchResults(resultArray);
  };

  return (
    <div className="flex container mx-auto pt-5 pb-5 border-b  ">
      <div className="w-3/12">
        <img className="h-10" src={logo} />
      </div>
      <div className="flex w-9/12 justify-between">
        <div className="flex flex-col pl-3 rounded-md w-7/12 items-center bg-[#00000010] relative">
          <div className="flex w-full items-center justify-between">
            <img src={SearchIcon} className="h-6 w-6" />
            <form onSubmit={handleSubmit} className="w-[95%]">
              <input
                type="text"
                id="search"
                className="bg-transparent pl-3 outline-none w-[100%] p-2"
                onBlur={delayblur}
                onChange={handleSearchChange}
                placeholder="Search Users by Name, ID Number or Phone Number"
              />
              <button className="hidden">submit</button>
            </form>
          </div>
          {visibleList ? (
            <div className="flex-1 bg-white absolute z-40 max-h-36  min-h-max left-0 right-0 p-4 top-11 -bottom-36 rounded shadow overflow-scroll  scrollbar-hide">
              {isLoading ? (
                <div className="flex justify-center items-center h-full">
                  <ActivityLoader />
                </div>
              ) : searchResults.length === 0 ? (
                <p className="border-t border-gray-200 p-2">No Result found</p>
              ) : (
                searchResults.map((i) => (
                  <p
                    key={i._id}
                    onClick={() => {
                      navigate(`/User/data`, { state: { user: i } });
                    }}
                    className="border-t border-gray-200 p-2 cursor-pointer capitalize"
                  >
                    {i.firstname?.toLowerCase() + " " + i.surname?.toLowerCase()}
                  </p>
                ))
              )}
            </div>
          ) : null}
        </div>

        <div className="flex items-center">
          <h1 className="text-[#00000080] text-sm">
            Logged in as: <Link className="text-[#2FBF00] capitalize">{adminUser}</Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Nav;
