import React, { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import axios from "axios";
import closeBtn from "../../assets/closeBtn.svg";

import { useNavigate, useHref } from "react-router-dom";

export default function Modal({ setPopupModal, hasRefresh, hasRedirect }) {
  const navigate = useNavigate();
  const history = useHref();

  const { modalContent, setModalContent } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleDelete = () => {
    setLoading(true);
    axios
      .delete(`https://kycbackendapp.onrender.com/api/user/${modalContent._id}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setModalContent(null);
          setPopupModal(false);
          if (hasRefresh) {
            navigate(0);
          }
          if (hasRedirect) {
            navigate("/Users");
          }
        }
      })
      .catch((error) => {
        setError(error.response.data.error);
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="bg-[#3e4f6440] absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center">
      <div className="w-2/4 bg-white h-1/5 rounded-md shadow-md px-20 flex flex-col -mt-14">
        {/* <div className=" flex justify-end">
          <button className="text-red-500" onClick={() => setPopupModal(false)}>
            <img src={closeBtn} alt="close" />
          </button>
        </div> */}
        {error && <p className="text-red-400 text-center">{error}</p>}
        <div className="mt-2 flex-1 flex flex-col justify-evenly">
          <p className="text-center  ">
            Are you sure you want to delete{" "}
            <span className="font-semibold">
              {modalContent.firstname} {modalContent.surname}{" "}
            </span>
            from KYC AFRICA?
          </p>
          <div className="items-center flex gap-4 justify-center">
            <button
              className=" bg-[#FF0000] w-28 hover:bg-[#FF000090] p-2 rounded text-[#FFFFFF] "
              onClick={() => setPopupModal(false)}
            >
              No
            </button>
            <button className="bg-[#2FBF00] hover:bg-[#2FBF0090] w-28  text-white p-2 rounded" onClick={handleDelete}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
