import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useContext, useEffect, useState } from "react";
import { ActivityLoader } from "./components/ActivityLoader";
import Content from "./components/Content";
import DashMenu from "./components/DashMenu";
import InnerHeader from "./components/InnerHeader";
import Modal from "./components/Modal";
import Nav from "./components/Nav";
import UserDetails from "./components/UserDetails";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../context/ModalContext";
import moment from "moment";
import deleteIcon from "../assets/delete.svg";
import { BatchResults } from "../context/BatchResults";

function BatchFail() {
  const { batchResults } = useContext(BatchResults);

  console.log(batchResults);
  const [sortingDropdown, setsortingDropdown] = useState(false);
  const [popupModal, setPopupModal] = useState(false);
  const [checkBoxVisible, setcheckBoxVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [fetchedData, setfetchedData] = useState([]);
  const [userDisplayed, setUserDisplayed] = useState(false);

  const navigate = useNavigate();

  const menu = [
    {
      id: 1,
      name: "Dashboard",
      state: false,
      to: "/Dashboard",
    },
    {
      id: 1,
      name: "Users",
      state: true,
      to: "/Users",
    },
    {
      id: 1,
      name: "Reports",
      state: false,
      to: "/Reports",
    },
    {
      id: 1,
      name: "Administrators",
      state: false,
      to: "/Administrators",
      role: "SuperAdmin",
    },
    {
      id: 1,
      name: "Settings",
      state: false,
      to: "/Settings",
      role: "SuperAdmin",
    },
  ];

  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle={"Batch Results"} />
        {!userDisplayed ? (
          <Content>
            <InnerHeader
              SectionHeader="Batch ID Verification Results"
              SectionDescription={
                <p>
                  Showing <span className="font-semibold">{batchResults[1]?.length}</span> failed Results
                </p>
              }
              setcheckBoxVisible={setcheckBoxVisible}
              checkBoxVisible={checkBoxVisible}
              menu={menu}
              hidden={true}
              hidden2={false}
            />

            {/* table */}
            {!loading ? (
              <div className="overflow-y-scroll w-full " style={{ height: `calc(100vh - 200px)` }}>
                {/*  */}
                <DataTable
                  value={batchResults[1]}
                  paginatorPosition="bottom"
                  paginator={fetchedData.length > 10 ? true : false}
                  selectionMode="single"
                  rows={10}
                  rowsPerPageOptions={[10]}
                  totalRecords={fetchedData?.length}
                  height={400}
                >
                  <Column className="uppercase" field="personNo" header="ID" sortable />
                  <Column className="capitalize" field="firstName" header="Name" sortable />
                  <Column className="capitalize" field="surname" header="Surname" sortable />
                  <Column className="capitalize" field="dateOfBirth" header="Date of Birth" sortable />
                  <Column className="capitalize" field="sex" header="Sex" sortable />
                </DataTable>
                {/*  */}
              </div>
            ) : (
              <ActivityLoader message="Fetching Your Data..." />
            )}
            {popupModal ? <Modal setPopupModal={setPopupModal} hasRefresh /> : null}
          </Content>
        ) : (
          <UserDetails />
        )}
      </div>
    </div>
  );
}

export default BatchFail;
