import React, { useEffect } from "react";
import DashMenu from "./components/DashMenu";
import Content from "./components/Content";
import { ActivityLoader } from "./components/ActivityLoader";
import { useNavigate, useParams } from "react-router-dom";
import { useRequests } from "../requests/useRequests";
import Nav from "./components/Nav";
import back_icon from "../assets/back_icon.svg";
import ReportItemCard from "./components/ReportItemCard";

export const ListedLogView = () => {
  const { id, title } = useParams();
  const navigate = useNavigate();
  const { fetchMyLogs, allMyFetchedLogs, isLoading } = useRequests();

  const userName = allMyFetchedLogs[0]?.user.firstname;
  const userSurname = allMyFetchedLogs[0]?.user.surname;

  // creating us group of items
  let filteredItems = allMyFetchedLogs.filter((items) => {
    console.log(items?.home?.streetName);
    if (items.home) {
      return items?.home?.streetName.trim() === title;
    } else {
      return items?.work?.streetName.trim() === title;
    }
  });

  useEffect(() => {
    fetchMyLogs(id);
  }, []);
  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle="List Logs" />
        <Content>
          {isLoading ? (
            <ActivityLoader />
          ) : (
            <>
              <div className="pb-3 flex justify-between items-center">
                <h4>
                  Address Verification Logs:{" "}
                  <span className="font-semibold text-[#2FBF00] uppercase">
                    {userName} {userSurname}
                  </span>{" "}
                </h4>
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-xs p-2 pr-3 items-center flex gap-2 hover:bg-gray-600  cursor-pointer bg-gray-500 rounded-[5px] text-white"
                >
                  <img src={back_icon} className="h-4 w-4" alt={"back_icon"} />
                  Back
                </button>
              </div>
              <div className=" w-full overflow-auto" style={{ height: `calc(100vh - 150px)` }}>
                {filteredItems.map((i) => (
                  <ReportItemCard i={i} />
                  //   <GroupedCard i={i} />
                ))}
              </div>
            </>
          )}
        </Content>
      </div>
    </div>
  );
};
