import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import AdminLogin from "./pages/AdminLogin";
import { Administrators } from "./pages/Administrators";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/PageNotFound";
import { Reports } from "./pages/Reports";
import { Settings } from "./pages/Settings";
import SingleLogsView from "./pages/SingleLogsView";
import Users from "./pages/Users";
import VerificationApi from "./pages/VerificationApi";
import UserDetails from "./pages/components/UserDetails";
import UserPrint from "./pages/components/UserPrint";

import { useEffect, useState } from "react";
import { UserContext } from "./context/UserContext";

import { LogsContext } from "./context/LogsContext";

import { ToastContainer } from "react-toastify";
import { AdminContext } from "./context/AdminContext";
import { BatchResults } from "./context/BatchResults";
import { CheckResults } from "./context/CheckResults";
import { ModalContext } from "./context/ModalContext";
import { PrintContext } from "./context/PrintContext";
import BatchFail from "./pages/BatchFail";
import BatchIDs from "./pages/BatchIDs";
import { ListedLogView } from "./pages/ListedLogView";

function App() {
  const [user, setUser] = useState(null);
  const [logs, setLogs] = useState(null);
  const [modalContent, setModalContent] = useState();
  const [adminModal, setAdminModal] = useState();
  const [printData, setPrintData] = useState(null);
  const [batchResults, setBatchResults] = useState([]);
  const [checkResults, setCheckResults] = useState(null);

  useEffect(() => {
    if (!user) {
      const client = JSON.parse(localStorage.getItem("admin"));
      setUser(client);
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <PrintContext.Provider value={{ printData, setPrintData }}>
        <LogsContext.Provider value={{ logs, setLogs }}>
          <ModalContext.Provider value={{ modalContent, setModalContent }}>
            <AdminContext.Provider value={{ adminModal, setAdminModal }}>
              <BatchResults.Provider value={{ batchResults, setBatchResults }}>
                <Router>
                  <Routes>
                    <Route path="/login" element={!user ? <AdminLogin /> : <Navigate to="/" />} />
                    {/* main routes */}
                    <Route
                      path="/"
                      element={
                        user ? (
                          !user.isSuper ? (
                            <VerificationApi />
                          ) : (
                            <Dashboard />
                          )
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    />
                    <Route path="/Users" element={user ? <Users /> : <Navigate to="/login" />} />
                    <Route
                      path="/Reports"
                      element={user ? <Reports /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/Reports/Logs/:id"
                      element={user ? <SingleLogsView /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/Reports/Logs/:id/:title"
                      element={user ? <ListedLogView /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/Administrators"
                      element={user ? <Administrators /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/Settings"
                      element={user ? <Settings /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/User/data"
                      element={user ? <UserDetails /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/User/data/print-preview"
                      element={user ? <UserPrint /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/Verification"
                      element={user ? <VerificationApi /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/BatchVerification"
                      element={user ? <BatchIDs /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/BatchResults"
                      element={user ? <BatchFail /> : <Navigate to="/login" />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Router>
              </BatchResults.Provider>
            </AdminContext.Provider>
          </ModalContext.Provider>
        </LogsContext.Provider>
      </PrintContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
