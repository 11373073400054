import moment, { months } from "moment";
import { useState } from "react";

export function Filtering() {
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [febData, setFebData] = useState([]);
  const [marData, setMarData] = useState([]);
  const [aprData, setAprData] = useState([]);
  const [mayData, setMayData] = useState([]);
  const [junData, setJunData] = useState([]);
  const [julData, setJulData] = useState([]);
  const [augData, setAugData] = useState([]);
  const [sepData, setSepData] = useState([]);
  const [octData, setOctData] = useState([]);
  const [novData, setNovData] = useState([]);
  const [janData, setJanData] = useState([]);
  const [decData, setDecData] = useState([]);

  const [combinedData, setCombinedData] = useState([]);
  // console.log(combinedData)

  const [dd, setDd] = useState();

  // console.log(allData)
  for (let i = 0; combinedData.length < i; i++) {
    console.log(combinedData[1][i]?.title);
  }

  const getAllData = async () => {
    setIsLoading(true);
    let fetchOk = (...args) =>
      fetch(...args).then((res) =>
        res.ok
          ? res
          : res.json().then((data) => {
              throw Object.assign(new Error(data.error_message), { name: res.statusText });
            })
      );

    Promise.all(
      [`https://kycbackendapp.onrender.com/api/home/`, `https://kycbackendapp.onrender.com/api/work/`].map((url) =>
        fetchOk(url).then((r) => r.json())
      )
    )
      .then(([d1, d2]) => {
        setAllData([...d1, ...d2]);
        //
        if (allData) {
          const janData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Jan");
          const febData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Feb");
          const marData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Mar");
          const aprData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Apr");
          const mayData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "May");
          const junData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Jun");
          const julData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Jul");
          const augData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Aug");
          const sepData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Sep");
          const octData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Oct");
          const novData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Nov");
          const decData = allData?.filter((i) => moment(i.createdAt).format("MMM") === "Dec");

          setJanData(janData);
          setFebData(febData);
          setMarData(marData);
          setAprData(aprData);
          setMayData(mayData);
          setJunData(junData);
          setJulData(julData);
          setAugData(augData);
          setSepData(sepData);
          setOctData(octData);
          setOctData(novData);
          setDecData(decData);

          //Work Addresses
          let janWork = toFilterCount(janData, "work");
          let febWork = toFilterCount(febData, "work");
          let marWork = toFilterCount(marData, "work");
          let aprWork = toFilterCount(aprData, "work");
          let mayWork = toFilterCount(mayData, "work");
          let junWork = toFilterCount(junData, "work");
          let julWork = toFilterCount(julData, "work");
          let augWork = toFilterCount(augData, "work");
          let sepWork = toFilterCount(sepData, "work");
          let octWork = toFilterCount(octData, "work");
          let novWork = toFilterCount(novData, "work");
          let decWork = toFilterCount(decData, "work");

          //Home Addreses

          let janHome = toFilterCount(janData, "home");
          let febHome = toFilterCount(febData, "home");
          let marHome = toFilterCount(marData, "home");
          let aprHome = toFilterCount(aprData, "home");
          let mayHome = toFilterCount(mayData, "home");
          let junHome = toFilterCount(junData, "home");
          let julHome = toFilterCount(julData, "home");
          let augHome = toFilterCount(augData, "home");
          let sepHome = toFilterCount(sepData, "home");
          let octHome = toFilterCount(octData, "home");
          let decHome = toFilterCount(decData, "home");
          let novHome = toFilterCount(novData, "home");
        }
        // // filtering
        setIsLoading(false);
      })
      .catch((e) => console.error(e));
  };
  // getAllData()

  return [getAllData, isLoading, febData, allData];
}

export function toFilterCount(DataMonth, condition) {
  const data = DataMonth?.filter((i) => i.title === condition);
  return data;
}
