import React, { useEffect, useState } from "react";
import Nav from "./components/Nav";
import DashMenu from "./components/DashMenu";
import Content from "./components/Content";
import Select from "react-select";
import { ActivityLoader } from "./components/ActivityLoader";
import moment from "moment";
import { PiX } from "react-icons/pi";

const countries = [
  { value: "ZW", label: "Zimbabwe", id_placeholder: "63221109X07" },
  // { value: 'ZM', label: 'Zambia', id_placeholder: '72251109/S07/20' },
  // { value: 'BW', label: 'Botswana', id_placeholder: 'BW2498599 85/94' },
  // { value: 'NM', label: 'Namibia', id_placeholder: '43578/54 53' },
  // { value: 'ZA', label: 'South Africa', id_placeholder: '597896/85/20' }
];

const VerificationApi = () => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [data, setData] = useState();
  const [hasToken, setHasToken] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [errorMsg, setErrorMsg] = useState({
    error: "",
    message: "",
  });
  const [checkResult, setCheckResult] = useState();
  const [idvError, setIdvError] = useState();
  const [idResults, setIdResults] = useState("");

  const kycLogin = (e) => {
    setSelectedCountry(e);

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`https://verify.kycafrica.com/api/auth/token?username=${"KYCAFRICA"}&password=${"qwertyui"}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setHasToken(JSON.parse(result));
      });
  };

  const handleVerification = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIdvError();
    setIdResults("");
    if (!data) {
      setIdvError({ error: "Please enter a valid ID number in the format '63221109X07' and try again" });
      setIsLoading(false);
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${hasToken.access_token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://verify.kycafrica.com/api/verifyid/${data}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let feedback = JSON.parse(result);
        if (feedback.status === "E") {
          setIdvError({ error: "Your ID does not exist, please check and try again" });
          setIsLoading(false);
        } else if (feedback) {
          if (data.length <= 11) {
            setIdResults(data);
          }
          setCheckResult(feedback);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    kycLogin();
  }, []);

  return (
    <div>
      {idvError && (
        <div className="fixed  top-0 left-0 right-0 bg-red-100 flex px-10 h-20 z-20">
          <div className="flex-1 flex justify-center items-center">
            <p className=" text-red-500">{idvError.error}</p>
          </div>
          <button
            className=" text-slate-900 text-xl"
            onClick={() => {
              setIdvError();
            }}
          >
            <PiX />
          </button>
        </div>
      )}
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle="Settings" />
        <Content>
          <div className="mt-5 flex justify-between">
            <div className="w-[48%]">
              <h1 className="text-sm font-semibold mt-[2%]">ID Verification</h1>
              {/* <p className=" mt-[2%]">KYC ID verification service that enables you to authenticate IDs in only a few seconds.

                            </p> */}
              <div className=" mt-[3%] pt-[3%]">
                <h5 className=" mb-[2%] text-sm   text-[#2FBF00]">Select the country of origin for the ID you want to verify</h5>
                <Select
                  options={countries}
                  defaultValue={countries[0]}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isClearable={isClearable}
                  isRtl={isRtl}
                  isSearchable={isSearchable}
                  name="countries"
                  className=" outline-[#2FBF00]"
                  onChange={(e) => kycLogin(e)}
                  theme={(theme, state) => ({
                    ...theme,
                    ...state,
                    borderRadius: 3,

                    colors: {
                      ...theme.colors,
                      primary25: "#ccedc1",
                      primary: "#2FBF00",
                    },
                  })}
                />
                <form className=" mt-[3%]">
                  <h5 className=" mt-[4%] mb-[2%] text-sm  text-[#2FBF00]  ">
                    To verify a single ID please enter the ID No. in the format provided below
                  </h5>
                  <input
                    className=" mb-[2%] bg-transparent pl-3 w-full p-2 outline-[0.5px] outline-[#2FBF00] border-[1px] border-[#E0E0E0]  rounded-[4px] "
                    type="text"
                    onChange={(e) => {
                      setIdvError();
                      setData(e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase());
                    }}
                  />
                  <p className="text-gray-500 text-sm uppercase ">
                    {countries[0].label} ID format: {countries[0].id_placeholder}
                  </p>

                  <input
                    type="submit"
                    disabled={isLoading}
                    onClick={handleVerification}
                    value={isLoading ? "Verifying..." : "Verify IDs"}
                    className="mb-[2%] hover:bg-[#248f00] mt-[5%] cursor-pointer text-[#fff] bg-[#2FBF00] px-[7%] py-[1%] rounded-[4px] disabled:bg-slate-200  disabled:cursor-not-allowed "
                  />
                </form>
                {/* <div className="mt-[4%] text-sm">
                                    <h1 className='uppercase mb-[1%] text-gray-600 font-semibold'>How to do a multiple ID Check?</h1>
                                    <ol className="list-decimal pl-5 gap-y-3 flex flex-col ">
                                        <li>
                                            <p className="text-gray-600">Create an excel sheet with a single column of all the ID Numbers you want to check</p>
                                        </li>
                                        <li>
                                            <p className="text-gray-600">This search can only return ID numbers that fail the check as a csv file</p>
                                        </li>
                                    </ol>

                                </div> */}
              </div>
            </div>
            <div className="w-[48%]  h-[80vh] rounded-[4px] bg-[#efefef] pt-[1%] overflow-clip">
              <div className="flex  flex-col w-[100%] px-[5%] justify-center align-middle">
                {hasToken ? (
                  <tt className="mt-[2%] mb-[2%] text-sm text-center capitalize  text-[#2FBF00]">Logged in to the server</tt>
                ) : (
                  <tt className="mt-[2%] mb-[2%] text-sm  text-[#FF0000] text-center ">Offline: Logging in ...</tt>
                )}
                {!isLoading ? (
                  checkResult ? (
                    <div className=" flex-col items-center px-[5%] py-[5%] bg-white  rounded-[4px]">
                      <div className="h-[60vh] text-base w-[100%] gap-y-[3%] flex flex-col">
                        <div className="grid grid-cols-2 w-[100%]">
                          <p>First Name:</p>
                          <p className="capitalize font-semibold">{checkResult.firstName?.toLowerCase()}</p>
                        </div>
                        <div className="grid grid-cols-2 ">
                          <p>Surname:</p>
                          <p className="capitalize font-semibold">{checkResult.surname?.toLowerCase()}</p>
                        </div>
                        <div className="grid grid-cols-2 ">
                          <p>National ID No.:</p>
                          <p className="uppercase font-semibold">
                            {idResults !== "" ? idResults.toLowerCase() : checkResult.personNo?.toLowerCase()}
                          </p>
                        </div>
                        <div className="grid grid-cols-2">
                          <p>Date of Birth:</p>
                          <p className="capitalize font-semibold">{checkResult.dateOfBirth}</p>
                        </div>
                        <div className="grid grid-cols-2">
                          <p>Gender:</p>
                          <p className="capitalize font-semibold">{checkResult.sex === "M" ? "Male" : "Female"}</p>
                        </div>
                        <div className="grid grid-cols-2">
                          <p>Life Status:</p>
                          <p className="uppercase font-semibold -ml-2 ">
                            {checkResult.status === "A" ? (
                              <p className="text-[#fff] px-2 rounded-[3px] bg-[#2FBF00] ">Alive</p>
                            ) : (
                              <p className="text-[#FFF]  px-2 rounded-[2px]  bg-[#FF0000]">Deceasesed </p>
                            )}
                          </p>
                        </div>
                      </div>

                      <button className="mb-[2%] mt-[2%] cursor-pointer text-[#fff] bg-[#2FBF00] w-[100%] text-sm items-center px-[7%] py-[1%] rounded-[4px] ">
                        Print Result
                      </button>
                    </div>
                  ) : (
                    <div className="mt-[40%] text-center text-sm">
                      {" "}
                      <p className="text-center text-[#4f4f4f]">Your Results will appear here</p>
                    </div>
                  )
                ) : (
                  <div className="mt-[40%] text-center text-sm">
                    <ActivityLoader />
                    <p>Please Wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
};

export default VerificationApi;
