import React from 'react'

const StatCard = ({ statNumber, title, description, handleClick }) => {

    return (
        <div>
            <div className="bg-[#2FBF0010] hover:bg-[#2FBF0025] w-11/12 p-4 h-full flex flex-col rounded-md cursor-pointer" onClick={handleClick}>
                <h1 className="text-[32pt] text-[#2FBF00]">{statNumber}</h1>
                <h3 className="font-semibold pb-2 text-[10pt] ">{title}</h3>
                <p className="text-[8pt] text-gray-500">
                    {description}
                </p>
            </div>
        </div>
    )
}

export default StatCard;