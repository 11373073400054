import React, { useEffect, useState } from "react";
import Nav from "./components/Nav";
import DashMenu from "./components/DashMenu";
import Content from "./components/Content";
import { useRequests } from "../requests/useRequests";
import { ActivityLoader } from "./components/ActivityLoader";
import ReportItemCard from "./components/ReportItemCard";
import SelectableLogs from "./components/SelectableLogs";

export function Reports() {
  const { allDataReports, reporting, isLoading } = useRequests();

  // filtering data data
  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item.user && item.user[key], item])).values()];
  }

  // const arr1 = [];
  const arr1 = getUniqueListBy(allDataReports, "idNumber");

  useEffect(() => {
    reporting();
  }, []);

  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle="Reports" />
        <Content>
          {isLoading ? (
            <ActivityLoader />
          ) : allDataReports ? (
            // ? replace below with a table package
            <>
              <div>
                <h4 className="text-sm font-semibold mt-[2%]">Address Verification Logs</h4>
              </div>
              <div className=" w-full overflow-auto pt-5" style={{ height: `calc(100vh - 150px)` }}>
                {arr1.map((i) => (
                  <SelectableLogs item={i} />
                ))}
              </div>
            </>
          ) : (
            // ? package end
            <p>Reports not available</p>
          )}
        </Content>
      </div>
    </div>
  );
}
