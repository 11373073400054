import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment/moment";
import { Filtering } from "./FilteredState";



//----------------------------------------
// export async function toFilterCount(DataMonth, condition) {

//   const data = await DataMonth?.filter((i) => i.title === condition);
//   return data
// }

// export async function toFilterHome(DataMonth, condition) {

//   const homeData = await DataMonth?.filter((i) => i.homeVerified === condition);

//   return homeData

// }

// export async function toFilterWork(DataMonth, condition) {


//   const workData = await DataMonth?.filter((i) => i.workVerified === condition);
//   return workData

// }



//--------------------------------------


const DashChart = ({ data }) => {

  const [getAllData, febHomeFail, janData, febData, marData, aprData, mayData, junData, julData, augData, sepData, octData, novData, decData,] = Filtering();

  const janmonth = janData
  const febmonth = febData
  const marmonth = marData
  const aprmonth = aprData
  const maymonth = febData
  const junmonth = febData
  const julmonth = febData
  const augmonth = febData
  const sepmonth = febData
  const octmonth = febData
  const novmonth = febData
  const decmonth = febData



  //home verification
  const fHS = febmonth?.filter((i) => i.homeVerified === "success");
  const fHP = febmonth?.filter((i) => i.homeVerified === "pending");
  const fHF = febmonth?.filter((i) => i.homeVerified === "failed");



  //work verificarion
  const fWS = febmonth?.filter((i) => i.workVerified === "success");
  const fWP = febmonth?.filter((i) => i.workVerified === "pending");
  const fWF = febmonth?.filter((i) => i.workVerified === "failed");


  //home verification
  const mHS = marmonth?.filter((i) => i.homeVerified === "success");
  const mHP = marmonth?.filter((i) => i.homeVerified === "pending");
  const mHF = marmonth?.filter((i) => i.homeVerified === "failed");

  console.log('====================================');
  console.log(mHS);
  console.log('====================================');

  //work verificarion
  const mWS = marmonth?.filter((i) => i.workVerified === "success");
  const mWP = marmonth?.filter((i) => i.workVerified === "pending");
  const mWF = marmonth?.filter((i) => i.workVerified === "failed");





  //home verification
  const aHS = aprmonth?.filter((i) => i.homeVerified === "success");
  const aHP = aprmonth?.filter((i) => i.homeVerified === "pending");
  const aHF = aprmonth?.filter((i) => i.homeVerified === "failed");



  //work verificarion
  const aWS = aprmonth?.filter((i) => i.workVerified === "success");
  const aWP = aprmonth?.filter((i) => i.workVerified === "pending");
  const aWF = aprmonth?.filter((i) => i.workVerified === "failed");





  const objectsData = [
    {
      month: "Jan",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(53, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(36, 70%, 50%)",

    },
    {
      month: "Feb",
      "Verified Home ": fHS?.length,
      "Verified HomeColor": "hsl(346, 70%, 50%)",
      "Pending Home ": fHP?.length,
      "Pending Home Color": "hsl(107, 70%, 50%)",

    },
    {
      month: "Mar",
      // "Verified Home ": mHS?.length,
      // "Verified HomeColor": "hsl(346, 70%, 50%)",
      // "Pending Home ": mHP?.length,
      // "Pending Home Color": "hsl(107, 70%, 50%)",
      // "Verified Work ": mWS?.length,
      // "Verified WorkColor": "hsl(196, 70%, 50%)",
      // "Pending Work ": mWP?.length,
      // "Pending Work Color": "hsl(37, 70%, 50%)",
    },

    {
      month: "Apr",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",
      "Verified Work ": 0,
      "Verified WorkColor": "hsl(345, 70%, 50%)",
      "Pending Work ": 0,
      "Pending Work Color": "hsl(34, 70%, 50%)",
    },
    {
      month: "May",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
    {
      month: "Jun",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
    {
      month: "Jul",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
    {
      month: "Aug",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
    {
      month: "Sept",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
    {
      month: "Oct",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
    {
      month: "Nov",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
    {
      month: "Dec",
      "Verified Home ": 0,
      "Verified HomeColor": "hsl(293, 70%, 50%)",
      "Pending Home ": 0,
      "Pending Home Color": "hsl(147, 70%, 50%)",

    },
  ];

  useEffect(() => {
    getAllData()
    return () => {

    }
  }, [])

  return (
    <div className="h-[50vh] -ml-5)">
      {
        <ResponsiveBar
          data={objectsData}
          keys={["Verified Home ", "Pending Home "]}
          indexBy="month"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: false }}
          colors={["#00BB56", "#C4E9D5", "#FF8115", "#FFD6B4"]}
          enableLabel={false}
          defs={[
            {
              id: "dots",

              background: "inherit",

              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",

              background: "inherit",

              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: "Verified Home",
              },
              id: "dots",
            },
            {
              match: {
                id: "Verified Work ",
              },
              id: "lines",
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.7]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Registered Addresses",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
        />
      }
    </div>
  );
};

export default DashChart;
