import moment from "moment";
import React from "react";

export default function ReportItemCard({ i }) {
  const location = JSON.parse(i.currentLocation[0]);
  // console.log(location);

  let user = i.user.firstname + " " + i.user.surname;

  const handleSingleView = (i, { user }) => {
    // console.log(i);
  };
  let userLoc = location.latitude + "," + location.longitude;

  return (
    <div
      className="bg-[#00000009]  grid grid-cols-3 items-center gap-4 mb-6 p-4 rounded"
      onClick={() => handleSingleView(i)}
      key={i._id}
    >
      {/* <div>
        <p className=" text-sm font-semibold  capitalize ">{i.user.firstname}</p>
        <p className=" text-sm font-semibold  capitalize">{i.user.surname}</p>
      </div> */}
      <div>
        <p className=" text-sm">Location Address:</p>
        <p className="capitalize  font-semibold">
          {i.home
            ? i.home?.houseNo + " " + i.home?.streetName + " "
            : i.work?.building + " " + i.work?.streetName + " "}
        </p>
      </div>
      <div>
        <p className=" text-sm">Checked on:</p>
        <p className="capitalize  font-semibold">{moment(i.createdAt).format("lll")}</p>
      </div>

      <div className="flex flex-col">
        <p className=" text-sm">Location GPS Cordinates:</p>
        <div className=" flex gap-5">
          <p className="capitalize hover:underline hover:text-[#2FBF00] font-semibold">
            <a href={`https://www.google.com/maps/?q=${location.latitude},${location.longitude}`} target="_blank">
              {" "}
              Lat:{location.latitude}
              {" : "} Lng:{location.longitude}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
