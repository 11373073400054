import React, { useContext, useEffect, useRef, useState } from "react";
import Nav from "./components/Nav";
import DashMenu from "./components/DashMenu";
import Content from "./components/Content";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useFetcher } from "react-router-dom";

export const Settings = () => {
  const { user } = useContext(UserContext);

  const [password, setPassword] = useState();
  const [previousPassword, setPreviousPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const formRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password && !previousPassword) {
      setError("Please complete the form below");
      return;
    }
    if (password !== confirmPassword) {
      setError("Please complete the form below");
    } else {
      setLoading(true);
      // action
      axios
        .patch(`https://kycbackendapp.onrender.com/api/admin/update-password/${user._id}`, {
          previousPassword,
          password,
        })
        .then((res) => {
          toast.success("Password has been updated successfully");
          formRef.current.reset();
          setLoading(false);
        })
        .catch((error) => {
          setError(error.response.data.message);
          setLoading(false);
        });
      // action
    }
  };

  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle="Settings" />
        <Content>
          <div className="h-full w-full flex  ">
            <div className="bg-white rounded w-1/2 h-1/2 p-3">
              <h5 className="text-sm font-semibold mt-[2%]">Change password</h5>

              <form onSubmit={handleSubmit} ref={formRef} className="mt-[3%]">
                <div className="my-2">
                  <input
                    input="text"
                    type="password"
                    placeholder="Enter Old Password"
                    required
                    onChange={(e) => setPreviousPassword(e.target.value)}
                    className=" mb-[2%] bg-transparent pl-3 w-full p-2 outline-[0.5px] outline-[#2FBF00] border-[1px] border-[#E0E0E0]  rounded-[4px] "
                  />
                </div>
                <div className="my-2">
                  <input
                    input="password"
                    type="password"
                    placeholder="Enter New Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className=" mb-[2%] bg-transparent pl-3 w-full p-2 outline-[0.5px] outline-[#2FBF00] border-[1px] border-[#E0E0E0]  rounded-[4px] "
                  />
                </div>
                <div className="my-2">
                  <input
                    input="password"
                    type="password"
                    placeholder="Retype New Password"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className=" mb-[2%] bg-transparent pl-3 w-full p-2 outline-[0.5px] outline-[#2FBF00] border-[1px] border-[#E0E0E0]  rounded-[4px] "
                  />
                </div>
                <input
                  disabled={loading}
                  value={loading ? " Please wait . . ." : "Update Password"}
                  type="submit"
                  className="bg-[#2FBF00] h-10 w-full rounded-[4px] text-white font-medium hover:cursor-pointer hover:bg-[#27A000]"
                />
              </form>
              {error && (
                <div className="bg-red-100 text-red-500 font-light text-sm rounded-sm px-3 py-1 mb-3 mt-3">
                  Oops! {error}. Please try again.
                </div>
              )}
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
};
