import React, { useContext, useEffect, useState } from "react";
import Nav from "./components/Nav";
import Content from "./components/Content";
import UserDetails from "./components/UserDetails";
import DashMenu from "./components/DashMenu";
import deleteIcon from "../assets/delete.svg";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { FilterMatchMode, FilterOperator } from 'primereact/api';

import moment from "moment";
import InnerHeader from "./components/InnerHeader";
import { useNavigate } from "react-router-dom";
import { ActivityLoader } from "./components/ActivityLoader";
import Modal from "./components/Modal";
import { ModalContext } from "../context/ModalContext";

function Users() {
  const { setModalContent } = useContext(ModalContext);

  const [sortingDropdown, setsortingDropdown] = useState(false);
  const [popupModal, setPopupModal] = useState(false);
  const [checkBoxVisible, setcheckBoxVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [fetchedData, setfetchedData] = useState([]);
  const [userDisplayed, setUserDisplayed] = useState(false);

  const navigate = useNavigate();

  const menu = [
    {
      id: 1,
      name: "Dashboard",
      state: false,
      to: "/Dashboard",
    },
    {
      id: 1,
      name: "Users",
      state: true,
      to: "/Users",
    },
    {
      id: 1,
      name: "Reports",
      state: false,
      to: "/Reports",
    },
    {
      id: 1,
      name: "Administrators",
      state: false,
      to: "/Administrators",
      role: "SuperAdmin",
    },
    {
      id: 1,
      name: "Settings",
      state: false,
      to: "/Settings",
      role: "SuperAdmin",
    },
  ];

  const fetchingData = () => {
    setloading(true);
    fetch("https://kycbackendapp.onrender.com/api/user")
      .then((response) => response.json())
      .then((data) => {
        setfetchedData(data);
        setloading(false);
      });
  };

  useEffect(() => {
    fetchingData();
  }, []);

  const userCount = fetchedData?.length;

  const dateBodyTemplate = (data) => {
    return <p value={moment(data.createdAt).format("LLL")}>{moment(data.createdAt).format("LL")}</p>;
  };

  const nameBodyTemplate = (data) => {
    return (
      <p style={{ textTransform: "capitalize" }} value={data.firstname}>
        {data.firstname?.toLowerCase()}
      </p>
    );
  };

  const surnameBodyTemplate = (data) => {
    return (
      <p style={{ textTransform: "capitalize" }} value={data.surname}>
        {data.surname?.toLowerCase()}
      </p>
    );
  };

  const dataBodyRemove = (data) => {
    return (
      <button
        onClick={() => {
          setModalContent(data);
          setPopupModal(true);
        }}
        className="bg-[#FF0000] p-2 rounded"
      >
        <img src={deleteIcon} alt="Delete" />
      </button>
    );
  };

  const handlenavigateWithUser = (e) => {
    if (e.value.hasOwnProperty("firstname")) {
      navigate(`/User/data`, { state: { user: e.value } });
    } else {
      alert(`User with mobile number: ${e.value.phone} has not yet complete the id process`);
    }
  };

  return (
    <div>
      <Nav />
      <div className="flex container mx-auto mt-3 ]" style={{ height: `calc(100vh - 100px)` }}>
        <DashMenu pageTitle={"Users"} />
        {!userDisplayed ? (
          <Content>
            <InnerHeader
              SectionHeader="Registered App Users"
              SectionDescription={
                <p>
                  Showing <span className="font-semibold">{fetchedData.length}</span> registered users
                </p>
              }
              setcheckBoxVisible={setcheckBoxVisible}
              checkBoxVisible={checkBoxVisible}
              menu={menu}
              hidden={false}
              hidden2={false}
            />

            {/* table */}
            {!loading ? (
              <div className="overflow-y-scroll w-full " style={{ height: `calc(100vh - 200px)` }}>
                {/*  */}
                <DataTable
                  value={fetchedData}
                  paginatorPosition="bottom"
                  paginator={fetchedData.length > 10 ? true : false}
                  selectionMode="single"
                  rows={10}
                  rowsPerPageOptions={[10]}
                  totalRecords={fetchedData?.length}
                  onSelectionChange={(e) => {
                    handlenavigateWithUser(e);
                  }}
                  height={400}
                >
                  <Column body={dateBodyTemplate} header="Date" />
                  {/* <Column field="firstname" header="Name" /> */}
                  <Column body={nameBodyTemplate} header="Name" />
                  <Column body={surnameBodyTemplate} header="Surname" />
                  <Column field="idNumber" header="ID" />
                  <Column field="phone" header="Phone" />
                  {/* <Column body={dataBodyRemove} header="Delete" /> */}
                </DataTable>
                {/*  */}
              </div>
            ) : (
              <ActivityLoader message="Fetching Your Data..." />
            )}
            {popupModal ? <Modal setPopupModal={setPopupModal} hasRefresh /> : null}
          </Content>
        ) : (
          <UserDetails />
        )}
      </div>
    </div>
  );
}

export default Users;

{
  /* <Column field="surname" header="Surname" />
<Column field="idNumber" header="ID" />
<Column field="phone" header="Phone" /> */
}
