import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SelectableLogs({ item }) {
  const navigate = useNavigate();

  const user = item.user;
  const home = item.home;

  const handleClick = (item) => {
    if (user !== null && home !== null) navigate(`/Reports/Logs/${item.user._id}`);
  };

  return (
    <div
      key={item._id}
      onClick={() => handleClick(item)}
      className="bg-[#00000009] hover:bg-[#00000013] rounded p-4 mb-3 cursor-pointer grid grid-cols-3 gap-2"
    >
      <div>
        {user !== null ? (
          <>
            <p className="text-gray-800 font-medium text-sm">
              <span className="font-semibold text-[#2FBF00] uppercase">
                {user?.firstname} {user?.surname}
              </span>
            </p>
            <p className="text-gray-800 font-semibold text-sm flex items-center">
              <span className="font-semibold">{user?.idNumber}</span>{" "}
              {home === null && <ExclamationCircleIcon className="h-4 w-4 text-red-500" />}
            </p>
          </>
        ) : (
          <div className="">
            <p className="text-gray-800 font-semibold  text-sm uppercase">{item.username}</p>
            <p className="text-red-500 font-semibold text-sm uppercase ">Removed</p>
          </div>
        )}
      </div>
      <div>
        <p className="text-gray-800 font-medium text-sm">Account Registered on:</p>
        <p className="text-gray-800 font-medium text-sm">
          <span className="font-semibold">{moment(user?.createdAt).format("LLL")}</span>
        </p>
      </div>
      <div className="flex justify-between">
        <div>
          {user && (
            <>
              <p className="text-gray-800 font-medium text-sm">Phone Number:</p>
              <p className="text-gray-800 font-medium text-sm">
                <span className="font-semibold">{user?.phone}</span>{" "}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
