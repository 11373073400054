import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/kyc-logo.svg";
import logo2 from "../assets/rilpix_logo.svg";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { ActivityLoader } from "./components/ActivityLoader";
import { Oval } from "react-loader-spinner";

function AdminLogin() {
  const { setUser } = useContext(UserContext);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  // event handles
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    setError(null);
    setLoading(true);
    e.preventDefault();
    if (!username || !password) {
      setError("All fields are required");
    }
    axios
      .post(`https://kycbackendapp.onrender.com/api/admin/login`, {
        username,
        password,
      })
      .then((res) => {
        localStorage.setItem("admin", JSON.stringify(res.data));
        // console.log(res);
        setUser(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };
  // login setup
  useEffect(() => {
    setLoading(false);

    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <div className="container mx-auto h-[100vh]  ">
      <div className="flex  justify-between w-5/5 mx-auto items-center  h-[100vh] gap-20 align">
        <div className="flex flex-1 justify-center items-center  ">
          <img className="h-20 mb-5 " src={logo} />
          {/* <h1 className="text-2xl w-5/6 font-medium text-[#2FBF00]">
            Smart Know Your Customer Solutions in one Place.
          </h1> */}
        </div>
        <div className="border-l-gray-100 border-r h-2/3 "></div>
        <div className="flex w-2/3 justify-center flex-1 flex-col">
          <form className="mb-3" onSubmit={handleSubmit}>
            <input
              input="email"
              placeholder="Username"
              required
              onChange={(e) => setUsername(e.target.value)}
              className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none focus:bg-[#2FBF0020]"
            />
            <input
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
              className="bg-[#F2F2F2] h-10 w-full rounded-md text-black mb-5 pl-3 outline-none  focus:bg-[#2FBF0020] "
            />
            <input
              disabled={loading}
              value={loading ? " Signing you in . . ." : "Sign in"}
              type="submit"
              className="bg-[#2FBF00] h-10 w-full rounded-md text-white font-medium text-lg hover:cursor-pointer hover:bg-[#27A000]"
            />
          </form>
          {error && (
            <div className="bg-red-100 text-red-500 font-light text-sm rounded-sm px-3 py-1 mb-3">
              Oops! {error}. Please try again.
            </div>
          )}
          <Link className="text-sm font-light text-[#2FBF00] w-full text-center hover:underline">Forgot Password?</Link>
        </div>
      </div>

      {/* <div className="footer fixed bottom-0 left-0 right-0">
        <p className="flex justify-center pt-5 pb-5 text-gray-500">&copy;{new Date().getFullYear()} Developed by KYC AFRICA</p>
      </div> */}
    </div>
  );
}

export default AdminLogin;
