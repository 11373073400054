import React from "react";
import { Oval } from "react-loader-spinner";

export const ActivityLoader = ({ message }) => {
  return (
    <div className="h-2/3 w-1/2 m-auto flex flex-col justify-center items-center">
      <Oval
        height={40}
        width={40}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#2FBF00"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <p className="text-[#505050] text-sm mt-3 ">{message}</p>
    </div>
  );
};
